<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import customTable from "@/components/paginate-table.vue";
import axios from "axios";
import mobileView from "../../../components/mobile-view.vue";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, customTable, mobileView },
  page: {
    title: "Penjualan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "#/",
        },
        {
          text: "Order",
          active: true,
        },
      ],
      apiUrl: process.env.VUE_APP_API_BASE_URL + "cloth-favorites",
      apiCategory: process.env.VUE_APP_API_BASE_URL + "customer-categories",
      clothCategoryUrl: process.env.VUE_APP_API_BASE_URL + "cloth-categories",
      sizeUrl: process.env.VUE_APP_API_BASE_URL + "sizes",
      colorUrl: process.env.VUE_APP_API_BASE_URL + "colors",
      dataKey: "stock",
      sizes: [],
      cloth_category: [],
      colors: [],
      data: {
        prop: "referral",
        label: "Referral",
        sortable: true,
        center: true,
        width: 150,
      },
      params: [
        {
          'name': 'include_omset_profit',
          'value': 1
        }
      ],
      screenWidth: 0,
      category: [],
      categoryName: [],
      columns: [
        {
          prop: "category_name",
          label: "Nama",
          center: true,
          width: 200,
        },
        {
          prop: "color_name",
          label: "Warna",
          center: true,
          width: 150,
        },
        {
          prop: "size",
          label: "Ukuran",
          center: true,
          width: 150,
        },
        {
          prop: "total_sale",
          label: "Total Terjual",
          center: true,
          width: 150,
        },
        {
          prop: "date",
          label: "Tanggal",
          center: true,
          width: 150,
          date: true
        }
      ],
    };
  },
  methods: {
    async getSize() {
      try {
        await axios.get(this.sizeUrl).then((response) => {
          const uniqueRoles = [
            ...new Set(
              response.data.data.map((data) => {
                return data;
              })
            ),
          ];

          this.sizes = uniqueRoles.map((size) => ({
            label: size.name,
            value: size.name,
            id: size.id,
          }));
        });
      } catch (error) {
        console.error(error);
      }
    },

    async getClothCategory() {
      try {
        await axios.get(this.clothCategoryUrl).then((response) => {
          const uniqueRoles = [
            ...new Set(
              response.data.data.map((data) => {
                return data;
              })
            ),
          ];

          this.cloth_category = uniqueRoles.map((category) => ({
            value: category.name,
            label: category.name,
            id: category.id,
          }));
        });
      } catch (error) {
        console.error(error);
      }
    },

    async getColors() {
      try {
        await axios.get(this.colorUrl).then((response) => {
          const uniqueRoles = [
            ...new Set(
              response.data.data.map((data) => {
                return data;
              })
            ),
          ];

          this.colors = uniqueRoles.map((color) => ({
            value: color.name,
            label: color.name,
            id: color.id,
          }));
        });
      } catch (error) {
        console.error(error);
      }
    },

    async getCategory() {
      try {
        this.isLoading = true;
        await axios.get(this.apiCategory).then((response) => {
          const uniqueRoles = [
            ...new Set(
              response.data.data.map((data) => {
                return data;
              })
            ),
          ];

          this.categoryName = uniqueRoles.map((role) => ({
            name: role.name,
            value: role.name,
            id: role.id,
          }));
        });
      } catch (error) {
        console.error(error);
      }
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    this.updateScreenWidth();
    this.onScreenResize();
    this.getCategory();
    this.getSize();
    this.getClothCategory();
    this.getColors();
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout v-if="screenWidth >= 860">
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body>
        <custom-table
          ref="dataTable"
          :with-add="false"
          :with-form-add="false"
          :with-revenue-order="false"
          :form-url="'/penjualan/tambah'"
          :custom-detail-url="'/penjualan/'"
          :with-fixed="true"
          :with-show="false"
          :no-action="true"
          :with-delete="false"
          :with-print="false"
          :with-edit="false"
          :data-url="apiUrl"
          :data-key="dataKey"
          :data-columns="columns"
          :merge="data"
          :action="true"
          :with-filter-name="true"
          :with-filter-color-multiple="true"
          :with-filter-size-multiple="true"
          :with-filter-cloth-category="true"
          :select-options="sizes"
          :category-option="cloth_category"
          :select-colors="colors"
          title-filter="Filter Berdasarkan Nama Kategori Produk"
          filter-with="category_name"
        ></custom-table>
      </b-card-body>
    </b-card>
  </Layout>
  <mobileView
    v-else
    ref="dataTable"
    title-filter="Filter Berdasarkan Nama Pelanggan"
    :with-filter-text="true"
    :with-filter-date="true"
    :with-filter-order="true"
    :with-filter-created="true"
    :with-filter-category="true"
    :with-delete="false"
    :select-options="categoryName"
    :form-url="'/penjualan/tambah'"
    :data-url="apiUrl"
    skeleton-radius="5px"
    :card-default="false"
    :card-order="true"
    filter-with="customer_name"
  ></mobileView>
</template>

<style scoped>
.badge-custom {
  display: inline-block;
  padding: 0.4em 0.8em;
  font-size: 80%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.5rem;
}
</style>
